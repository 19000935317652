import * as React from 'react';
import { tableStyles } from './tableStyles';
//import { IRefCallBack } from 'components/hooks/useKeyboardNavigation';
import clsx from 'clsx';

interface ITrProps {
  className?: string;
  children?: any;
  name?: string;
  data?: any;
  onClick?: (event: any) => any;
  //  refCallBack?: (arg: IRefCallBack) => any;
}

const Tr = (props: ITrProps) => {
  const tableClasses = tableStyles();

  /*
 ref={
        props.refCallBack
          ? (node: any) =>
              props.refCallBack({
                data: props.data,
                name: props.name,
                node: node,
              })
          : undefined
      }  */
  return (
    <tr className={clsx(props.className, tableClasses.tr, props.onClick ? 'clickable' : null)} onClick={props.onClick}>
      {props.children}
    </tr>
  );
};

export default Tr;
