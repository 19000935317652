import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface ILanguageEdit {
  languageID: number;
  code: string;
  name: string;
}

export const getLanguageEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/Language/edit', config);
  return response.data;
};

export const updateLanguageEdit = async (item: ILanguageEdit) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/Language/edit', item, config);
  return response.data;
};
