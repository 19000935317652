import { createEntityAdapter } from '@reduxjs/toolkit';
import { IEppData } from 'application/project/api/EppData';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';

export const GetEppDataID = (data: { supply: PlenumDataSupply; inside: PlenumDataInside; plenumIndex: number }) =>
  [data.supply, data.inside, data.plenumIndex].join('_');

export const eppDataAdapter = createEntityAdapter<IEppData>({
  selectId: (item) => GetEppDataID(item),
});
