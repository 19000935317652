import { getArticleEdit, IArticleEdit } from 'application/editcomp/api/Article';
import {
  enTranslationType,
  getTranslationEdit,
  getTranslationsFromRecord,
  ITranslationEdit,
} from 'application/editcomp/api/Translation';
import { selectAllLanguageEdit } from 'application/editcomp/redux/selectors/languageEditSelector';
import { fetchAsyncLanguageEdit } from 'application/editcomp/redux/thunks/fetchAsyncLanguageEdit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// const columns = [
//   columnHelper.accessor('articleID', {
//     cell: (info) => info.getValue(),
//   }),
//   columnHelper.accessor('number', {
//     cell: (info) => info.getValue(),
//   }),
//   columnHelper.accessor('name', {
//     cell: (info) => info.getValue(),
//   }),
// columnHelper.accessor((row) => row.name, {
//   id: 'name',
//   cell: (info) => <i>{info.getValue()}</i>,
//   header: () => <span>Last Name</span>,
//   footer: (info) => info.column.id,
// }),
// columnHelper.accessor('age', {
//   header: () => 'Age',
//   cell: (info) => info.renderValue(),
//   footer: (info) => info.column.id,
// }),
// columnHelper.accessor('visits', {
//   header: () => <span>Visits</span>,
//   footer: (info) => info.column.id,
// }),
// columnHelper.accessor('status', {
//   header: 'Status',
//   footer: (info) => info.column.id,
// }),
// columnHelper.accessor('progress', {
//   header: 'Profile Progress',
//   footer: (info) => info.column.id,
// }),
//];

export interface IArticleEditEx extends IArticleEdit {
  translations: { [id: number]: ITranslationEdit };
}
const useArticleGridData = () => {
  const dispatch = useDispatch();
  const [articles, setArticles] = React.useState<IArticleEdit[]>([]);
  const [translations, setTranslations] = React.useState<ITranslationEdit[]>([]);

  React.useEffect(() => {
    dispatch(fetchAsyncLanguageEdit({}));
    getArticleEdit().then((result) => setArticles(result));
    getTranslationEdit({ translationTypeID: enTranslationType.articleName }).then((result) => setTranslations(result));
  }, [dispatch]);

  const articleEditUpdated = React.useCallback(
    (ValveConnectorGridEdit: IArticleEdit) => {
      setArticles((items) =>
        items.map((item) => {
          if (item.articleID === ValveConnectorGridEdit.articleID) {
            return ValveConnectorGridEdit;
          } else {
            return item;
          }
        })
      );
    },
    [setArticles]
  );

  const languages = useSelector(selectAllLanguageEdit);

  return React.useMemo(
    () => ({
      articles: articles.map(
        (item) =>
          ({
            ...item,
            translations: getTranslationsFromRecord(item.articleID, translations),
          } as IArticleEditEx)
      ),
      articleEditUpdated,
      languages,
    }),
    [articles, translations, articleEditUpdated, languages]
  );
};

export default useArticleGridData;
