import { IValveConnection } from 'application/project/api/Connection';
import { IPlenumData } from 'application/project/api/PlenumData';
import * as React from 'react';
import connectionStyles from './controls/connectionStyles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { makeSelectPlenumConnectionsByConnection } from 'application/project/redux/selectors/plenumConnectionSelectors';
import PartPlenumConnection from './PartPlenumConnection';

const PartPlenumConnections = (props: { plenumdata: IPlenumData; valveConnection: IValveConnection }) => {
  const classes = connectionStyles();
  const selectPlenumConnectionsByConnection = React.useMemo(makeSelectPlenumConnectionsByConnection, []);
  const plenumConnections = useSelector((state) =>
    selectPlenumConnectionsByConnection(state, props.valveConnection.valveConnectionKey)
  );

  return (
    <div className={clsx(classes.partBlock)}>
      {plenumConnections.map((plenumConnection) => (
        <PartPlenumConnection
          key={plenumConnection.indexNumber}
          plenumdata={props.plenumdata}
          valveConnection={props.valveConnection}
          plenumConnection={plenumConnection}
        />
      ))}
    </div>
  );
};

export default PartPlenumConnections;
