import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IEppTerminalEdit {
  eppTerminalID: number;
  name: string;
  eppTerminalTypeID: number;
  eppColorID: number;
  eppDiameterID: number;
  articleID: number;
  adapterID: number;
  roofArticleID: number;
  roofConnectArticleID: number;
  zetaSupply: number;
  zetaExtraction: number;
  isDeleted: boolean;
}
export const getEppTerminalEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppTerminal/edit', config);
  return response.data;
};

export const updateEppTerminalEdit = async (item: IEppTerminalEdit) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/EppTerminal/edit', item, config);
  return response.data;
};
