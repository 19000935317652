import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IArticleEdit {
  articleID: number;
  number: string;
  name: string;
  quantityPerItem: number;
  isDeleted: boolean;
  imageID: number;
}

export const formatArticleEdit = (article: IArticleEdit) => (article ? article?.number + ': ' + article?.name : '');

export const getArticleEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/Article/edit', config);
  return response.data;
};

export const updateArticleEdit = async (item: IArticleEdit) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/Article/edit', item, config);
  return response.data;
};
