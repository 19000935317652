import * as React from 'react';
import { tableStyles } from './tableStyles';
import clsx from 'clsx';
import useObserver from '../hooks/useObserver';

export interface ISortingFields {
  onClick: (name: string) => any;
  currentOrderBy: string;
}
export interface IThProps {
  className?: string;
  children?: any;
  colSpan?: number;
  rowSpan?: number;
  style?: any;
  title?: string;
  firstRow?: boolean;
  firstCell?: boolean;
  OnWidthChanged?: (width: number) => any;
  OnHeightChanged?: (height: number) => any;
  onClick?: (name: string) => any;
  dataField?: string;
  sortingFields?: ISortingFields;

  stickyRowTop?: number;
  stickyColumnLeft?: number;

  borderLeft?: string;
  borderRight?: string;
  borderBottom?: string;
  borderTop?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const Th = (props: IThProps) => {
  const tableClasses = tableStyles();
  const cell = React.useRef();
  const cellWidth = React.useRef<number>();
  const cellHeight = React.useRef<number>();
  const { onClick, sortingFields, dataField, OnWidthChanged, OnHeightChanged } = props;

  let borderLeft = props.borderLeft;
  let borderRight = props.borderRight;
  let borderBottom = props.borderBottom;
  let borderTop = props.borderTop;

  if (borderTop === undefined) {
    if (props.firstCell) {
      borderTop = tableClasses.BorderTopBold;
    }
  }
  if (borderLeft === undefined) {
    if (props.firstCell) {
      borderLeft = tableClasses.BorderLeftBold;
    }
  }
  if (borderRight === undefined) borderRight = tableClasses.BorderRightBold;
  if (borderBottom === undefined) borderBottom = tableClasses.BorderBottomBold;

  const onCllickRef = React.useRef(props.onClick);
  if (onCllickRef.current !== props.onClick) {
    onCllickRef.current = props.onClick;
  }

  const someCallback = React.useCallback(
    (
      borderBox: { width: number; height: number },
      contentBox: { width: number; height: number },
      target: any,
      entry: any
    ) => {
      if (!OnWidthChanged && !OnHeightChanged) return;
      // code to run after render goes here
      let item: any = cell?.current;
      if (item && OnWidthChanged) {
        if (borderBox.width !== cellWidth.current) {
          cellWidth.current = borderBox.width;
          OnWidthChanged(borderBox.width);
        }
      }
      if (item && OnHeightChanged) {
        if (borderBox.height !== cellHeight.current) {
          cellHeight.current = borderBox.height;
          OnHeightChanged(borderBox.height);
        }
      }
    },
    [OnWidthChanged, OnHeightChanged]
  );

  useObserver({ callback: someCallback, element: cell });

  React.useEffect(() => {});
  const style: any = { ...props.style };

  if (props.textAlign) {
    style.textAlign = props.textAlign;
  }

  if (props.stickyRowTop !== undefined) {
    style.top = props.stickyRowTop;
    style.position = 'sticky';
    style.zIndex = 5;
  }

  if (props.stickyColumnLeft !== undefined) {
    style.left = props.stickyColumnLeft;
    style.position = 'sticky';
    style.zIndex = 4;
  }

  if (props.stickyRowTop !== undefined && props.stickyColumnLeft !== undefined) {
    style.zIndex = 6;
  }

  let newProps: any = { ...props };
  delete newProps.firstCell;
  delete newProps.borderTop;
  delete newProps.borderLeft;
  delete newProps.borderBottom;
  delete newProps.borderRight;
  delete newProps.stickyRowTop;
  delete newProps.stickyColumnLeft;
  delete newProps.OnHeightChanged;
  delete newProps.OnWidthChanged;
  delete newProps.textAlign;
  delete newProps.onClick;
  delete newProps.dataField;
  delete newProps.currentOrderBy;
  delete newProps.sortingFields;

  const orderBy = React.useMemo(() => {
    if (dataField && dataField === sortingFields?.currentOrderBy) {
      return <span>▼</span>;
    } else if (dataField && sortingFields?.currentOrderBy && sortingFields?.currentOrderBy.startsWith(dataField)) {
      return <span>▲</span>;
    } else {
      return undefined;
    }
  }, [dataField, sortingFields?.currentOrderBy]);

  const clickCallback = React.useCallback(
    () =>
      onClick ? onClick(dataField || '') : sortingFields?.onClick ? sortingFields.onClick(dataField || '') : undefined,
    [onClick, sortingFields, dataField]
  );

  return (
    <th
      {...newProps}
      ref={cell}
      onClick={clickCallback}
      style={style}
      className={clsx(
        tableClasses.tableBorder,
        tableClasses.cellPadding,
        props.stickyRowTop ? tableClasses.thead : null,
        borderLeft,
        borderBottom,
        borderRight,
        props.onClick ? 'clickable' : null,
        props.className
      )}>
      {props.children}
      {orderBy}
    </th>
  );
};

export default Th;
