import * as React from 'react';
import clsx from 'clsx';
import OptimisticCheckBox from './OptimisticCheckBox';
import { tableStyles } from './tableStyles';

interface IFilterTextProps {
  value?: boolean;
  title?: string;
  isTriState?: boolean;
  preventChange?: boolean;
  setFilter: (value: boolean | undefined) => void;
}

const FilterCheckBox = (props: IFilterTextProps) => {
  const tableClasses = tableStyles();
  return (
    <OptimisticCheckBox
      title={props.title}
      active={props.value}
      className={clsx(/*styles.root,*/ tableClasses.theadComponent)}
      isTriState={props.isTriState === undefined ? true : props.isTriState}
      preventChange={props.preventChange}
      onChange={(active: boolean | undefined) => props.setFilter(active)}
    />
  );
};

export default FilterCheckBox;
