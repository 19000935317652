import * as React from 'react';
import clsx from 'clsx';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export const CheckBoxer = (props: {
  value: boolean | undefined;
  name: string;
  label?: string;
  className?: string;
  onChange: (value: boolean, name: string, error: string) => any;
}) => {
  const { onChange } = props;

  return (
    <FormControlLabel
      className={props.className}
      control={
        <Checkbox
          checked={props.value}
          onChange={React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => onChange(event.target.checked, props.name, ''),
            [onChange, props.name]
          )}
          name={props.name}
        />
      }
      label={props.label}
    />
  );
};
