import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';

export interface IEppDataComponent {
  eppDataComponentID: number;
  supply: PlenumDataSupply;
  inside: PlenumDataInside;
  plenumIndex: number;
  eppComponentID?: number;
  eppTypeID?: number;
  amount?: number;
}

export const getEppDataComponents = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppDataComponent/' + guid, config);
  return response.data;
};

export const getEppDataComponent = async (guid: string, EppDataComponentID: number) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppDataComponent/' + guid + '/' + EppDataComponentID, config);
  return response.data;
};

export const updateEppDataComponent = async (
  guid: string,
  supply: PlenumDataSupply,
  inside: PlenumDataInside,
  plenumIndex: number,
  eppdatacomponent: IEppDataComponent
) => {
  const config = await bearerTokenHeader();
  const response = await axios.post(
    'api/EppDataComponent/' + guid + '/' + supply + '/' + inside + '/' + plenumIndex,
    eppdatacomponent,
    config
  );
  return response.data;
};
