import { createAsyncThunk } from '@reduxjs/toolkit';
import { getArticleEdit } from 'application/editcomp/api/Article';
import { selectCompEditState } from 'redux/store';

export const fetchAsyncArticleEdit = createAsyncThunk(
  'editcomp/fetchAsyncArticleEdit',
  async (data: {}, thunkAPI) => {
    const response = await getArticleEdit();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).articles.loading === 'init';
    },
  }
);
