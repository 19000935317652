import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppDatas, IEppData, updateEppData } from 'application/project/api/EppData';
import { selectProjectState } from 'redux/store';
import { selectAllEppDataComponents } from '../selectors/eppdataComponentSelectors';
import { selectAllEppDatas } from '../selectors/eppdataSelectors';

export const fetchAsyncEppDatas = createAsyncThunk(
  'project/eppdata/fetchAsyncEppDatas',
  async (data: { guid: string }, thunkAPI) => {
    const response = await getEppDatas(data.guid);
    return response;
  }
);

export const updateAsyncEppDatas = createAsyncThunk(
  'project/eppdata/updateAsyncEppDatas',
  async (data: { guid: string; eppdata: IEppData }, thunkAPI) => {
    const response = await updateEppData(
      data.guid,
      data.eppdata.supply,
      data.eppdata.inside,
      data.eppdata.plenumIndex,
      data.eppdata
    );
    return response;
  }
);

export const sinkAsyncEppData = (eppdata: IEppData) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      const eppDatas = selectAllEppDatas(getState());
      const eppDataComponents = selectAllEppDataComponents(getState());
      //we gaan alle andere eppdata's gelijk maken, maar alleen als er nog geen eppComponent of doorvoer is geselecteerd
      eppDatas.forEach((otherEppData) => {
        const componentsCount = eppDataComponents
          .filter((a) => a.supply === otherEppData.supply && a.inside === otherEppData.inside)
          .reduce((sum, a) => sum + (a.amount || 0), 0);

        if (
          !(
            otherEppData.supply === eppdata.supply &&
            otherEppData.inside === eppdata.inside &&
            otherEppData.plenumIndex === eppdata.plenumIndex
          ) &&
          componentsCount === 0 &&
          (!otherEppData.eppMaterialID ||
            otherEppData.eppMaterialID !== eppdata.eppMaterialID ||
            !otherEppData.eppDiameterID ||
            otherEppData.eppDiameterID !== eppdata.eppDiameterID)
        ) {
          const newData = {
            ...otherEppData,
            eppMaterialID: eppdata.eppMaterialID,
            eppDiameterID: eppdata.eppDiameterID,
          };
          if (projectState.activeProject) {
            dispatch(
              updateAsyncEppDatas({
                guid: projectState.activeProject?.guid,
                eppdata: newData,
              })
            );
          }
        }
      });
    }
  };
};

export const updateAsyncEppData = (eppdata: IEppData) => {
  return async (dispatch: any, getState: any) => {
    const projectState = selectProjectState(getState());
    if (projectState.activeProject) {
      dispatch(
        updateAsyncEppDatas({
          guid: projectState.activeProject?.guid,
          eppdata: eppdata,
        })
      );
      dispatch(sinkAsyncEppData(eppdata));
    }
  };
};
