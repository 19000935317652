import React from 'react';
import Settings from '../Settings/Settings';
import ValveConnectorGrid from '../ValveConnectorGrid/ValveConnectorGrid';
import ArticleEdit from '../Articles/ArticleEdit';
import EppTerminal from '../EppTerminals/EppTerminal';
import EppTerminalType from '../EppTerminalType/EppTerminalType';

export interface linkitem {
  to: string;
  title: string;
  role: string;
  component: React.ComponentType<any>;
}

export const EditComponentItems: linkitem[] = [
  {
    to: 'ValveConnectorGrid',
    title: 'ValveConnectorGrid',
    role: 'EditComponent',
    component: ValveConnectorGrid,
  },
  {
    to: 'Article',
    title: 'Articles',
    role: 'EditComponent',
    component: ArticleEdit,
  },
  {
    to: 'Terminals',
    title: 'Terminals',
    role: 'EditComponent',
    component: EppTerminal,
  },
  {
    to: 'TerminalTypes',
    title: 'Terminal types',
    role: 'EditComponent',
    component: EppTerminalType,
  },
  {
    to: 'Settings',
    title: 'Settings',
    role: 'SystemAdmin',
    component: Settings,
  },
];

export default EditComponentItems;
