import {
  ComponentEntityState,
  initialComponentEntityState,
  initialLoadingEntityState,
  LoadingEntityState,
} from 'redux/shared';
import { IValveConnectorEdit } from '../api/ValveConnectorGrid/ValveConnectorEdit';
import { IValveConnectorGridEdit } from '../api/ValveConnectorGrid/ValveConnectorGridEdit';
import { IValveGridEdit } from '../api/ValveConnectorGrid/ValveGridEdit';
import { valveConnectorEditAdapter } from './adapters/valveConnectorEditAdapter';
import { valveConnectorGridEditAdapter } from './adapters/valveConnectorGridEditAdapter';
import { valveGridEditAdapter } from './adapters/valveGridEditAdapter';
import { IArticleEdit } from 'application/editcomp/api/Article';
import { articleEditAdapter } from './adapters/articleEditAdapter';
import { ILanguageEdit } from '../api/Language';
import { languageEditAdapter } from './adapters/languageEditAdapter';
import { IEppTerminalEdit } from '../api/EppTerminal';
import { eppTerminalEditAdapter } from './adapters/eppTerminalEditAdapter';
import { IEppTerminalType } from 'application/components/api/EppTerminalType';
import { eppTerminalTypeAdapter } from 'application/components/redux/adapters/eppTerminalTypeAdapter';

export interface compeditState {
  languages: LoadingEntityState<ILanguageEdit>;
  articles: LoadingEntityState<IArticleEdit>;
  eppTerminals: LoadingEntityState<IEppTerminalEdit>;
  eppTerminalTypes: LoadingEntityState<IEppTerminalType>;
  valveConnectorGrids: LoadingEntityState<IValveConnectorGridEdit>;
  valveConnectors: ComponentEntityState<IValveConnectorEdit>;
  valveGrids: ComponentEntityState<IValveGridEdit>;
}

export const initialState: compeditState = {
  languages: languageEditAdapter.getInitialState(initialLoadingEntityState),
  articles: articleEditAdapter.getInitialState(initialLoadingEntityState),
  eppTerminals: eppTerminalEditAdapter.getInitialState(initialLoadingEntityState),
  eppTerminalTypes: eppTerminalTypeAdapter.getInitialState(initialLoadingEntityState),
  valveConnectorGrids: valveConnectorGridEditAdapter.getInitialState(initialLoadingEntityState),
  valveConnectors: valveConnectorEditAdapter.getInitialState(initialComponentEntityState),
  valveGrids: valveGridEditAdapter.getInitialState(initialComponentEntityState),
};
