import * as React from 'react';
import { selectAllEppColors } from 'application/components/redux/selectors/eppColorSelector';
import { selectAllEppComponents } from 'application/components/redux/selectors/eppComponentSelector';
import { selectAllEppDiameters } from 'application/components/redux/selectors/eppDiameterSelector';
import { selectAllEppMaterials } from 'application/components/redux/selectors/eppMaterialSelector';
import { selectAllEppTerminals } from 'application/components/redux/selectors/eppTerminalSelector';
import { selectAllEppTerminalTypes } from 'application/components/redux/selectors/eppTerminalTypeSelector';
import { GetEppDataID } from 'application/project/redux/adapters/eppDataAdapter';
import { makeSelectEppDataComponentsByDirection } from 'application/project/redux/selectors/eppdataComponentSelectors';
import { selectEppDataBySupplyInside } from 'application/project/redux/selectors/eppdataSelectors';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { useDispatch, useSelector } from 'react-redux';
import { IEppData } from 'application/project/api/EppData';
import { updateAsyncEppData } from 'application/project/redux/thunks/AsyncEppData';
import { IEppDataComponent } from 'application/project/api/EppDataComponent';
import { IEppMaterial } from 'application/components/api/EppMaterial';
import { IEppDiameter } from 'application/components/api/EppDiameter';
import { IEppTerminalType } from 'application/components/api/EppTerminalType';
import { IEppColor } from 'application/components/api/EppColor';
import { IEppComponent } from 'application/components/api/EppComponent';
import { useTranslation } from 'react-i18next';
import { selectAllEppDamperTypes } from 'application/components/redux/selectors/eppDamperTypeSelector';
import { selectAllEppDampers } from 'application/components/redux/selectors/eppDamperSelector';
import { IEppDamperType } from 'application/components/api/EppDamperTerminal';

export const isEppDataValid = (eppdata: IEppData): boolean => {
  if (!eppdata.eppMaterialID) return false;
  if (!eppdata.eppDiameterID) return false;
  if (eppdata.inside === PlenumDataInside.outside) {
    if (eppdata.eppTerminalID && (!eppdata.eppTerminalTypeID || !eppdata.eppColorID)) return false;
  }
  if (eppdata.inside === PlenumDataInside.inside) {
    if (eppdata.eppDamperID && !eppdata.eppDamperTypeID) return false;
  }
  return true;
};

export const useEppData = (data: {
  supply: PlenumDataSupply;
  inside: PlenumDataInside;
  plenumIndex: number;
}): [
  string,
  IEppData | undefined,
  IEppMaterial[],
  IEppDiameter[],
  IEppTerminalType[],
  IEppDamperType[],
  IEppColor[],
  {
    component: IEppComponent;
    eppDataComponent: IEppDataComponent | undefined;
  }[],
  (value: string | number | undefined | boolean, field: string, error: string) => any
] => {
  const dispatch = useDispatch();
  const diameters = useSelector(selectAllEppDiameters);
  const eppMaterials = useSelector(selectAllEppMaterials);
  const eppColors = useSelector(selectAllEppColors);
  const eppTerminalTypes = useSelector(selectAllEppTerminalTypes);
  const eppDamperTypes = useSelector(selectAllEppDamperTypes);
  const eppTerminals = useSelector(selectAllEppTerminals);
  const eppDampers = useSelector(selectAllEppDampers);
  /*
  const project = useSelector(selectActiveProject);
  const plenumCount =
    (data.supply === PlenumDataSupply.supply ? project?.supplyPlenums : project?.extractionPlenums) || 1;
    */
  const eppComponents = useSelector(selectAllEppComponents);
  const eppData = useSelector((state) => selectEppDataBySupplyInside(state, GetEppDataID(data)));
  const selectEppDataComponentsByDirection = React.useMemo(makeSelectEppDataComponentsByDirection, []);
  const eppDataComponents = useSelector((state) =>
    selectEppDataComponentsByDirection(state, data.supply, data.inside, data.plenumIndex)
  );
  const [editEppData, setEditEppData] = React.useState(eppData as IEppData);
  React.useEffect(() => setEditEppData(eppData as IEppData), [eppData]);

  const { t } = useTranslation(['eppedit']);

  const screenMaterial = React.useMemo(() => {
    return eppMaterials.filter((a) => eppComponents.find((c) => c.eppMaterialID === a.eppMaterialID));
  }, [eppMaterials, eppComponents]);

  const screenDiameter = React.useMemo(() => {
    if (!editEppData?.eppMaterialID) return [];
    return diameters.filter((a) =>
      eppComponents.find((c) => c.eppMaterialID === editEppData?.eppMaterialID && c.eppDiameterID === a.eppDiameterID)
    );
  }, [editEppData?.eppMaterialID, diameters, eppComponents]);

  const screenTerminalTypes = React.useMemo(() => {
    if (!editEppData?.eppDiameterID) return [];
    const retval = eppTerminalTypes.filter((a) =>
      eppTerminals.find(
        (c) => c.eppDiameterID === editEppData?.eppDiameterID && c.eppTerminalTypeID === a.eppTerminalTypeID
      )
    );
    if (retval.length > 0) {
      retval.splice(0, 0, {
        eppTerminalTypeID: 0,
        name: t('terminal_none', 'no terminal'),
      });
    }
    return retval;
  }, [t, editEppData?.eppDiameterID, eppTerminalTypes, eppTerminals]);

  const screenDamperTypes = React.useMemo(() => {
    if (!editEppData?.eppDiameterID) return [];
    const retval = eppDamperTypes.filter((a) =>
      eppDampers.find((c) => c.eppDiameterID === editEppData?.eppDiameterID && c.eppDamperTypeID === a.eppDamperTypeID)
    );
    if (retval.length > 0) {
      retval.splice(0, 0, {
        eppDamperTypeID: 0,
        name: t('damper_none', 'no damper'),
      });
    }
    return retval;
  }, [t, editEppData?.eppDiameterID, eppDamperTypes, eppDampers]);

  const screenColors = React.useMemo(() => {
    if (!editEppData?.eppDiameterID || !editEppData.eppTerminalTypeID) return [];
    return eppColors.filter((a) =>
      eppTerminals.find(
        (c) =>
          c.eppDiameterID === editEppData?.eppDiameterID &&
          c.eppTerminalTypeID === editEppData?.eppTerminalTypeID &&
          c.eppColorID === a.eppColorID
      )
    );
  }, [editEppData?.eppDiameterID, editEppData?.eppTerminalTypeID, eppColors, eppTerminals]);

  const screenComponents = React.useMemo(() => {
    if (!editEppData?.eppDiameterID || !editEppData.eppMaterialID) return [];
    return eppComponents
      .filter((a) => a.eppMaterialID === editEppData?.eppMaterialID && a.eppDiameterID === editEppData?.eppDiameterID)
      .map((component) => {
        return {
          component: component,
          eppDataComponent: eppDataComponents.find((a) => a.eppComponentID === component.eppComponentID),
        };
      })
      .sort((a, b) => a.component.eppTypeID - b.component.eppTypeID);
  }, [editEppData?.eppDiameterID, editEppData?.eppMaterialID, eppComponents, eppDataComponents]);

  const onChange = React.useCallback(
    (value: string | number | undefined | boolean, field: string, error: string) => {
      setEditEppData((prevValue) => {
        var newValue: IEppData = {
          ...prevValue,
          [field]: value,
        };
        //do we have a valid terminal (combination)?
        //
        if (data.inside === PlenumDataInside.outside) {
          if (
            !eppTerminals.find(
              (a) => a.eppDiameterID === newValue.eppDiameterID && a.eppTerminalTypeID === newValue.eppTerminalTypeID
            )
          ) {
            newValue.eppTerminalID = undefined;
            newValue.eppTerminalTypeID = undefined;
            newValue.eppColorID = undefined;
          } else if (
            !eppTerminals.find(
              (a) =>
                a.eppDiameterID === newValue.eppDiameterID &&
                a.eppTerminalTypeID === newValue.eppTerminalTypeID &&
                a.eppColorID === newValue.eppColorID
            )
          ) {
            newValue.eppColorID = undefined;
          }
        } else if (data.inside === PlenumDataInside.inside) {
          if (
            !eppDampers.find(
              (a) => a.eppDiameterID === newValue.eppDiameterID && a.eppDamperTypeID === newValue.eppDamperTypeID
            )
          ) {
            newValue.eppDamperID = undefined;
            newValue.eppDamperTypeID = undefined;
          }
        }

        if (isEppDataValid(newValue)) {
          dispatch(updateAsyncEppData(newValue));
        }
        return newValue;
      });
    },
    [dispatch, data.inside, eppTerminals, eppDampers]
  );

  const title = React.useMemo(
    () =>
      t(
        'title_' +
          (data.supply === PlenumDataSupply.supply ? 'supply' : 'extraction') +
          ' ' +
          (data.inside === PlenumDataInside.inside ? 'inside' : 'outside'),
        'Duct components ' +
          (data.supply === PlenumDataSupply.supply ? 'supply' : 'extraction') +
          ' ' +
          (data.inside === PlenumDataInside.inside ? 'inside' : 'outside')
      ) /* + (plenumCount > 0 ? ' ' + (data.plenumIndex + 1) : ''*/,
    [t, data.supply, data.inside /*, data.plenumIndex, plenumCount*/]
  );

  return [
    title,
    editEppData,
    screenMaterial,
    screenDiameter,
    screenTerminalTypes,
    screenDamperTypes,
    screenColors,
    screenComponents,
    onChange,
  ];
};
