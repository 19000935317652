import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';

export interface IEppData {
  supply: PlenumDataSupply;
  inside: PlenumDataInside;
  plenumIndex: number;
  eppMaterialID?: number;
  eppDiameterID?: number;
  eppColorID?: number;
  eppTerminalTypeID?: number;
  eppTerminalID?: number;
  eppDamperTypeID?: number;
  eppDamperID?: number;
  otherResistance: number;
}

export const getEppDatas = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/eppdata/' + guid, config);
  return response.data;
};

export const getEppData = async (
  guid: string,
  supply: PlenumDataSupply,
  inside: PlenumDataInside,
  plenumIndex: number
) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/eppdata/' + guid + '/' + supply + '/' + inside + '/' + plenumIndex, config);
  return response.data;
};

export const updateEppData = async (
  guid: string,
  supply: PlenumDataSupply,
  inside: PlenumDataInside,
  plenumIndex: number,
  eppdata: IEppData
) => {
  const config = await bearerTokenHeader();
  const response = await axios.put(
    'api/eppdata/' + guid + '/' + supply + '/' + inside + '/' + plenumIndex,
    eppdata,
    config
  );
  return response.data;
};
