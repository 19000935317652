import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';
import { ApiFormatGetUrl } from 'components/apiHelper';

export enum enTranslationType {
  articleName = 1,
  ductName,
  plenumName,
  valveGridName,
  bendName,
  restrictorName,
  filterName,
  ductConnectorName,
  ductAdapterName,
  eppColorName,
  eppMaterialName,
  eppTypeName,
  eppTerminalTypeName,
  eppDiameterName,
  eppDamperName,
  eppDamperTypeName,
}
export interface ITranslationEdit {
  translationID: number;
  languageID: number;
  recordID: number;
  translationTypeID: number;
  text: string;
}

export const getTranslationsFromRecord = (
  recordID: number,
  translations: ITranslationEdit[]
): { [id: number]: ITranslationEdit } =>
  translations
    .filter((a) => a.recordID === recordID)
    .reduce((accumulator, value) => {
      return { ...accumulator, [value.languageID]: value };
    }, {});

export const getTranslationEdit = async (arg: {
  translationTypeID?: enTranslationType;
  TranslationID?: number;
  RecordID?: number;
  LanguageID?: number;
}) => {
  const config = await bearerTokenHeader();
  const response = await axios.get(ApiFormatGetUrl('api/Translation/edit', arg), config);
  return response.data;
};

export const updateTranslationEdit = async (item: ITranslationEdit) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/Translation/edit', item, config);
  return response.data;
};
