import { makeStyles, Theme } from '@material-ui/core/styles';

export const tableStyles = makeStyles((theme: Theme) => ({
  table: {
    backgroundColor: '#ffffff',
    fontFamily: theme.typography.body1.fontFamily,
    fontSize: theme.typography.body1.fontSize,
  },

  margin: {
    marginBottom: theme.spacing(2),
  },

  theadComponent: {
    color: theme.palette.primary.contrastText + ' !important',
  },

  thead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  tr: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
    '&:focus-within': {
      backgroundColor: theme.palette.grey[300],
    },
  },
  linkSpan: {
    //color: 'blue' /* Stel de tekstkleur in op blauw (de standaardkleur voor links) */,
    textDecoration: 'none' /* Verwijder de onderstreping wanneer de muis erover zweeft */,
    cursor: 'pointer' /* Verander de cursor in een handje om aan te geven dat het klikbaar is */,
    '&:hover': {
      textDecoration: 'underline' /* Voeg een onderstreping toe */,
    },
  },
  cellPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 3,
    paddingRight: 3,
  },
  cellIconPadding: {
    paddingRight: 0,
  },
  editCellPadding: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableBorder: {
    borderCollapse: 'collapse',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: '#A0A0A0',
  },
  textFilterCell: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  BorderLeft: {
    borderLeftWidth: 1,
  },
  BorderRight: {
    borderRightWidth: 1,
  },
  BorderBottom: {
    borderBottomWidth: 1,
  },
  BorderTop: {
    borderTopWidth: 1,
  },
  BorderLeftBold: {
    borderLeftWidth: '2px !important',
  },
  BorderRightBold: {
    borderRightWidth: 2,
  },
  BorderBottomBold: {
    borderBottomWidth: 2,
  },
  BorderTopBold: {
    borderTopWidth: 2,
  },
  fit: {
    whiteSpace: 'nowrap',
    width: '1%',
  },

  splittHeadRow21: {
    paddingBottom: 0,
  },
  splittHeadRow22: {
    paddingTop: 0,
    paddingLeft: 1,
    paddingRight: 1,
  },

  stickyTable: {
    position: 'relative',
  },

  numberWidth: {
    minWidth: 70,
  },
  smallNumberWidth: {
    minWidth: 50,
  },
  totalCell: {
    fontWeight: 'bold',
  },

  fullWith: {
    width: '100%',
  },

  clickable: {
    cursor: 'pointer',
  },

  verticalAlignTop: {
    verticalAlign: 'top',
  },
  verticalAlignBottom: {
    verticalAlign: 'bottom',
  },

  stickyThead: {
    /*
    position: 'sticky',
    top: 50,
    zIndex: 5,
     Don't forget this, required for the stickiness */
  },
  stickyRowColor: {
    backgroundColor: '#ffffff',
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
      /* nog niet helemaal goed, als de TR hover heeft, moet mijn kleur ook veranderen */
    },
  },

  stickyTester: {
    //backgroundColor: '#ff0000',
  },

  divTable: {
    display: 'table',
  },
  divTableRow: {
    display: 'table-row',
  },
  divTableHead: {
    /*
    border: '1px solid #999999',
    padding: '3px 10px',
    */
    display: 'table-cell',
  },
  divTableCell: {
    /*
    border: '1px solid #999999',
    padding: '3px 10px',
    */
    display: 'table-cell',
  },
  divTableHeading: {
    /*
    backgroundColor: '#EEE',
    fontWeight: 'bold',
    */
    display: 'table-header-group',
  },
  divTableFoot: {
    /*
    backgroundColor: '#EEE',
    fontWeight: 'bold',
    */
    display: 'table-footer-group',
  },
  divTableBody: {
    display: 'table-row-group',
  },

  flexContainer: {
    display: 'flex',
  },

  flexGrowHor: {
    flexGrow: 1,
  },

  flexVerticalContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  flexColumn: {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
  },

  flexRow: {
    flex: '1',
    display: 'flex',
  },

  flexGrowVertical: {
    flex: 1,
  },

  iconDivInLineGrid: {
    display: 'inline-grid',
  },

  divFullCellSize: {
    width: '100%',
    height: '100%',
    margin: 0,
    padding: 0,
    boxSizing: 'border-box' /* Dit zorgt ervoor dat padding en borders binnen de 100% worden opgenomen */,
    position: 'absolute' /* Hiermee positioneren we de div binnen de td */,
    top: 0,
    left: 0,
  },
}));
