import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export const DUCT_WARN_LENGTH = 20;
export const DUCT_MAX_LENGTH = 25;
export const SECTIONS_AS_PLENUMCONNECTION_0 = 0;
export enum ConnectionDirty {
  dirty,
  saving,
  saved,
}
export interface IPlenumConnection {
  valveConnectionKey: string;
  supply: PlenumDataSupply;
  finalAirflowRate: number | undefined;
  connectionIndex: number | undefined;
  connectorRightAngled: boolean;
  restriction: string;
  indexNumber: number;
}

export interface ISection {
  valveConnectionKey: string;
  plenumConnectionIndex: number;
  ductID: number | undefined;
  ductLenght: number | undefined;
  indexNumber: number;
  ductAdapterID: number | undefined;
}

export interface ISectionBend {
  valveConnectionKey: string;
  plenumConnectionIndex: number;
  sectionIndex: number;
  bendID: number;
  amount: number;
}

export interface IValveConnection {
  valveConnectionKey: string;
  valveConnectionID: number | undefined;
  supply: PlenumDataSupply;
  plenumIndex: number;
  airflowRate: number | undefined;
  description: string;
  dual: boolean;
  hasFilter: boolean;
  customValveResistance: number | undefined;
  valveConnectorGridID: number | undefined;
  remove: boolean | undefined;
  dirty: ConnectionDirty;
}

export interface IApiSectionBend extends ISectionBend {}

export interface IApiSection extends ISection {
  bends: IApiSectionBend[];
}
export interface IApiPlenumConnection extends IPlenumConnection {
  sections: IApiSection[];
}

export interface IApiValveConnection extends IValveConnection {
  plenumConnections: IApiPlenumConnection[];
}

export const getConnections = async (guid: string) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/v2/connection/' + guid, config);
  return response.data;
};

export const getConnection = async (guid: string, connectionID: number) => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/v2/connection/' + guid + '/' + connectionID, config);
  return response.data;
};

export const updateConnection = async (guid: string, connection: IValveConnection) => {
  const config = await bearerTokenHeader();
  const response = await axios.post('api/v2/connection/' + guid, connection, config);
  return response.data;
};
