import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppTerminalTypeEdit } from 'application/editcomp/api/EppTerminalType';
import { selectCompEditState } from 'redux/store';

export const fetchAsyncEppTerminalTypeEdit = createAsyncThunk(
  'editcomp/fetchAsyncEppTerminalTypeEdit',
  async (data: {}, thunkAPI) => {
    const response = await getEppTerminalTypeEdit();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).eppTerminalTypes.loading === 'init';
    },
  }
);
