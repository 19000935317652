import RadioGroup from '@material-ui/core/RadioGroup';
import { IPlenum } from 'application/components/api/Plenum';
import { makeSelectPlenums } from 'application/components/redux/selectors/plenumSelector';
import { plenumConnectionAdapter } from 'application/project/redux/adapters/plenumConnectionAdapter';
import { selectPlenumdataById } from 'application/project/redux/selectors/plenumdataSelectors';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';
import { updateAsyncPlenumData } from 'application/project/redux/thunks/AsyncPlenumData';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { useGlobalStyles } from 'application/shared/styles';
import PageTitle from 'components/controls/PageTitle';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import useInfoDialog from 'components/dialog/useInfoDialog';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectState } from 'redux/store';
import { PlenumItem } from './PlenumItem';
import { createPlenumDataId } from 'application/project/redux/adapters/plenumDataAdapter';

export const PlenumSelector = (props: { supply: PlenumDataSupply; index: number; onNext: () => any }) => {
  const dispatch = useDispatch();
  const projectConnections = useSelector((state) => selectProjectState(state).valveConnections);
  const projectPlenumConnections = useSelector((state) => selectProjectState(state).plenumConnections);
  const project = useSelector(selectActiveProject);
  const selectPlenums = React.useMemo(makeSelectPlenums, []);
  const plenums = useSelector((state) => selectPlenums(state, props.supply, project?.ductTypeID));
  const plenumdata = useSelector((state) => selectPlenumdataById(state, createPlenumDataId(props)));
  const classes = useGlobalStyles();
  const [infoDialog, setInfoDialogOpen] = useInfoDialog();
  const { t } = useTranslation();

  const plenumSelect = React.useCallback(
    (plenum: IPlenum) => {
      if (plenumdata && plenumdata?.plenumID !== plenum.plenumID) {
        const valid = plenumConnectionAdapter
          .getSelectors()
          .selectAll(projectPlenumConnections)
          .reduce((isValid, plenumConnection) => {
            if (!isValid) return isValid;

            const valveConnection = projectConnections.entities[plenumConnection.valveConnectionKey];
            if (valveConnection?.supply === props.supply) {
              return (
                plenumConnection.connectionIndex === undefined ||
                plenumConnection.connectionIndex <= plenum.numberConnections
              );
            }

            return isValid;
          }, true);

        if (!valid) {
          setInfoDialogOpen({
            content: t(
              'plenum_connectionsnumberserror',
              'change plenum not possible, connections numbers will be invalid'
            ),
            title: t('plenum_connectionsnumberserrortitle', 'change plenum'),
          });
          return;
        }
        if (plenumdata && (plenumdata?.airflowRate || 0) > plenum.maxFlowRate + 0.1) {
          setInfoDialogOpen({
            content: t(
              'plenum_maxflowrateerror',
              'change plenum not possible, the total flow rate exceeds the maximum plenum flow rate'
            ),
            title: t('plenum_maxflowrateerrortitle', 'change plenum'),
          });
          return;
        }
        dispatch(
          updateAsyncPlenumData({
            ...plenumdata,
            plenumID: plenum.plenumID,
          })
        );
      }
    },
    [plenumdata, dispatch, projectConnections, projectPlenumConnections, props.supply, setInfoDialogOpen, t]
  );

  const title = React.useMemo(
    () =>
      t(
        'plenum_title' + (props.supply === PlenumDataSupply.supply ? 'supply' : 'extraction'),
        'Select plenum type for air ' + (props.supply === PlenumDataSupply.supply ? 'supply' : 'extraction')
      ),
    [t, props.supply]
  );

  return (
    <div className={classes.regularContent}>
      <PageTitle title={title} onNext={props.onNext} />
      <InfoDialogEx {...infoDialog} />
      <RadioGroup name="plenumID" value={plenumdata?.plenumID?.toString() || ''}>
        {plenumdata
          ? plenums.map((plenum) => (
              <PlenumItem key={plenum.plenumID} plenum={plenum} plenumdata={plenumdata} plenumSelect={plenumSelect} />
            ))
          : null}
      </RadioGroup>
      {/* <NextButton onNext={props.onNext} /> */}
    </div>
  );
};
