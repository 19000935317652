import { createAsyncThunk } from '@reduxjs/toolkit';
import { getEppTerminalEdit } from 'application/editcomp/api/EppTerminal';
import { selectCompEditState } from 'redux/store';

export const fetchAsyncEppTerminalEdit = createAsyncThunk(
  'editcomp/fetchAsyncEppTerminalEdit',
  async (data: {}, thunkAPI) => {
    const response = await getEppTerminalEdit();
    return response;
  },
  {
    condition(arg, thunkAPI) {
      return selectCompEditState(thunkAPI.getState()).eppTerminals.loading === 'init';
    },
  }
);
