import * as React from 'react';
import TextField from '@material-ui/core/TextField';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import useConstant from 'use-constant';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { ParseDecimal } from 'application/shared/format';

const useStyles = makeStyles((theme) => ({
  root: {},
  deleted: {
    textDecoration: 'line-through',
  },
  inputLabelProps: {
    overflowY: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));

export interface IValidateResult {
  warn: string;
  error: string;
}

export const validateNoError = () => {
  return {
    warn: '',
    error: '',
  };
};

export const lastUpdate: { LastDebouce: Date } = { LastDebouce: new Date() };

export default function TextInputField(props: {
  value: string | number | undefined;
  name?: string;
  label?: string;
  helperText?: string;
  onChange: (value: string, name: string, error: string) => void;
  validate?: (value: string) => IValidateResult;
  onBlur?: (value: string) => string;
  required?: boolean;
  debounce?: boolean;
  disabled?: boolean;
  deleted?: boolean;
  multiline?: boolean;
  inputClassName?: string;
  rows?: number;
  style?: any;
  readOnly?: boolean;
  type?: 'text' | 'int' | 'decimal';
  fullWidth?: boolean;
  className?: string;
  error?: boolean;
}) {
  const classes = useStyles();

  const { onChange, onBlur, validate } = props;

  const valueRef = React.useRef('');
  if (valueRef.current !== props.value?.toString()) {
    valueRef.current = props.value?.toString() || '';
  }

  const [TextValue, setTextValue] = React.useState<string>(props.value ? props.value.toString() : '');
  const [Error, setError] = React.useState<IValidateResult>(
    props.validate ? props.validate(props.value ? props.value.toString() : '') : validateNoError()
  );
  const [empty, setEmpty] = React.useState<boolean>(props.value ? false : true);

  const onChangeCallBack = React.useCallback(
    (value: string, name: string, error: string) => {
      if (valueRef.current !== value) {
        onChange(value, name, error);
      }
    },
    [onChange]
  );

  // Debounce the original search async function
  const searchAPIDebounced = useConstant(() => AwesomeDebouncePromise(onChangeCallBack, 1000));

  React.useEffect(() => {
    setTextValue(props.value ? props.value.toString() : '');
  }, [props.value]);

  const handleTextChange = React.useCallback(
    async (value: string, name: string) => {
      if (props.type === 'decimal') {
        if (value && !ParseDecimal(value)) {
          setTextValue('');
        }
      }
      setTextValue(value);
      let error = validate ? validate(value) : validateNoError();
      setError(error);
      if (props.debounce) {
        lastUpdate.LastDebouce = new Date();
        await searchAPIDebounced(value, name, error.error);
      } else {
        if (props.value !== value) {
          onChangeCallBack(value, name, error.error);
        }
      }
    },
    [onChangeCallBack, validate, props.debounce, searchAPIDebounced, props.type, props.value]
  );

  const helperTextRef = React.useRef(props.helperText);
  React.useEffect(() => {
    if (helperTextRef.current !== props.helperText) {
      helperTextRef.current = props.helperText;
      //als de helperText is aangepast, dan de boel opnieuw valideren
      const val = props.value ? props.value.toString() : '';
      let error = validate ? validate(val) : validateNoError();
      setError(error);
    }
  }, [props.helperText, props.value, validate]);

  return (
    <TextField
      fullWidth={props.fullWidth}
      className={clsx(classes.root, props.className)}
      style={props.style}
      value={TextValue}
      name={props.name}
      label={props.label}
      disabled={props.disabled || props.deleted}
      required={props.required}
      multiline={props.multiline}
      variant="filled"
      onFocus={(event) => {
        event.target.select();
      }}
      rows={props.rows}
      type={'text'}
      onChange={React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
          setEmpty(!event.target.value);
          handleTextChange(event.target.value, event.target.name);
        },
        [handleTextChange]
      )}
      onBlur={React.useCallback(
        (event: React.FocusEvent<HTMLInputElement>) => {
          if (event.target.value === '' && TextValue !== '') {
            setTextValue('');
          }
          if (onBlur) {
            handleTextChange(onBlur(event.target.value), event.target.name);
          }
        },
        [onBlur, handleTextChange, TextValue, setTextValue]
      )}
      InputProps={{
        readOnly: props.readOnly,
        classes: {
          input: clsx(props.deleted ? classes.deleted : null, props.inputClassName),
        },
      }}
      InputLabelProps={{
        className: classes.inputLabelProps,
      }}
      error={
        props.error !== undefined
          ? props.error
          : Error.error !== '' || (props.required && !props.value) || (props.required && empty)
      }
      helperText={Error.error ? Error.error : Error.warn ? Error.warn : props.helperText}
    />
  );
}
