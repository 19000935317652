import * as React from 'react';
import { ParseDecimal } from 'application/shared/format';
import TextInputField, { IValidateResult, validateNoError } from './TextInputField';

export default function NumberInputField(props: {
  value: number | undefined;
  name: string;
  label?: string;
  helperText?: string;
  onChange: (value: number | undefined, name: string, error: string) => any;
  validate?: (value: number) => IValidateResult;
  onBlur?: (value: string) => string;
  inputClassName?: string;
  required?: boolean;
  debounce?: boolean;
  disabled?: boolean;
  zeroAsEmpty?: boolean;
  style?: any;
  readOnly?: boolean;
  type: 'int' | 'decimal';
  fullWidth?: boolean;
  className?: string;
}) {
  const { onBlur, onChange, validate } = props;

  const valueRef = React.useRef<number | undefined>();
  let propsValue = props.value;
  if (props.zeroAsEmpty) {
    propsValue = props.value === 0 ? undefined : props.value;
  }

  if (valueRef.current !== propsValue) {
    valueRef.current = propsValue;
  }

  const myValidate = React.useCallback(
    (value: string): IValidateResult => {
      if (!value) return validateNoError();
      let val: number | undefined;
      if (props.type === 'int') {
        val = parseInt(value);
      } else {
        val = ParseDecimal(value);
      }
      if (val === undefined)
        return {
          warn: '',
          error: 'invalid value',
        };
      if (validate) return validate(val);
      return validateNoError();
    },
    [validate, props.type]
  );

  const onMyBlur = React.useCallback(
    (value: string) => {
      if (onBlur) return onBlur(value);

      let val: number | undefined;
      if (props.type === 'int') {
        val = parseInt(value);
      } else {
        val = ParseDecimal(value);
      }

      if (!val || isNaN(val)) return '';

      const error = myValidate(value);
      if (error?.error) {
        return props.value ? props.value.toString() : '';
      } else {
        return val.toString();
      }
    },
    [onBlur, props.type, myValidate, props.value]
  );

  const onLocalChange = React.useCallback(
    //deze wordt aangeroepen als validate is geslaagd, nog even een extra testje uitvoeren ...
    (value: string, name: string, error: string) => {
      let val: number | undefined;
      if (props.type === 'int') {
        val = parseInt(value);
      } else {
        val = ParseDecimal(value);
      }
      if (
        value.trim().length === 0 ||
        val === undefined ||
        val === null ||
        isNaN(val) ||
        (val === 0 && props.zeroAsEmpty)
      ) {
        val = undefined;
      }
      if (valueRef.current !== val) {
        onChange(val, name, error);
      }
    },
    [onChange, props.type, props.zeroAsEmpty]
  );

  return <TextInputField {...props} type="text" onChange={onLocalChange} onBlur={onMyBlur} validate={myValidate} />;
}
