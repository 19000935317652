export const ApiFormatGetUrl = (url: string, parameters: any) => {
  let newUrl = url;
  let result = '';
  for (var prop in parameters) {
    if (parameters[prop] !== undefined && parameters[prop] !== null) {
      if (Array.isArray(parameters[prop])) {
        for (let i = 0; i < parameters[prop].length; i++) {
          if (result !== '') result += '&';
          result += prop + '=' + encodeURIComponent(parameters[prop][i]);
        }
      } else {
        if (result !== '') result += '&';
        result += prop + '=' + encodeURIComponent(parameters[prop]);
      }
    }
  }
  if (result !== null) {
    if (url.indexOf('?') === -1) {
      newUrl += '?' + result;
    } else if (newUrl.indexOf('?') === newUrl.length - 1) {
      newUrl += result;
    } else {
      newUrl += '&' + result;
    }
  }
  return newUrl;
};
