import RegularPage from 'components/controls/RegularPage';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import useInfoDialog, { OpenInfoDialogProps } from 'components/dialog/useInfoDialog';
import React from 'react';
import { useGridStateData } from '../controls/hooks/useGridStateData';
import useEppTerminalGridData, { IEppTerminalEditEx } from './useEppTerminal';
import ReactEditTable, { booleanFilterFn } from 'components/table/ReactEditTable';
import { createColumnHelper } from '@tanstack/react-table';
import { formatArticleEdit } from 'application/editcomp/api/Article';
import DataCheckBox from 'components/table/DataCheckBox';

const columnHelper = createColumnHelper<IEppTerminalEditEx>();

export interface IGridStateData {
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void;
}

export const initExpandedData = (
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void
): IGridStateData => {
  return {
    openInfoDialog: openInfoDialog,
  };
};

const EppTerminal = () => {
  const data = useEppTerminalGridData();
  const [infoDialogState, openInfoDialog] = useInfoDialog();
  const gridStatePlaceHolder = useGridStateData(initExpandedData(openInfoDialog));

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('eppTerminalID', {}),
      columnHelper.accessor('isDeleted', {
        id: 'isDeleted',
        header: 'deleted',
        cell: (info) => <DataCheckBox value={info.getValue()} preventChange />,
        filterFn: booleanFilterFn,
      }),
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('zetaSupply', {}),
      columnHelper.accessor('zetaExtraction', {}),
      columnHelper.accessor((row) => row.eppTerminalType?.name || '', {
        id: 'eppTerminalType',
        header: 'terminalType',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.eppColor?.name || '', {
        id: 'eppColor',
        header: 'color',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => row.eppDiameter?.name || '', {
        id: 'eppDiameter',
        header: 'diameter',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => formatArticleEdit(row.article), {
        id: 'article',
        header: 'article',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => formatArticleEdit(row.roofArticle), {
        id: 'roofArticle',
        header: 'roofArticle',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => formatArticleEdit(row.roofConnectArticle), {
        id: 'roofConnectArticle',
        header: 'roofConnectArticle',
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor((row) => formatArticleEdit(row.adapterArticle), {
        id: 'adapter',
        header: 'adapter',
        cell: (info) => info.getValue(),
      }),
    ],
    []
  );

  return (
    <RegularPage title="Terminal">
      <InfoDialogEx {...infoDialogState} />
      <div>Terminal</div>
      <ReactEditTable
        state={gridStatePlaceHolder}
        data={data.eppTerminals}
        editUpdated={data.eppTerminalUpdated}
        columns={columns}
      />
    </RegularPage>
  );
};

export default EppTerminal;
