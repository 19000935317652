import { createSlice } from '@reduxjs/toolkit';
import { valveConnectorEditAdapter } from './adapters/valveConnectorEditAdapter';
import { valveConnectorGridEditAdapter } from './adapters/valveConnectorGridEditAdapter';
import { valveGridEditAdapter } from './adapters/valveGridEditAdapter';

import { initialState } from './editcompState';
import { fetchAsyncValveConnectorGridsEdit } from './thunks/fetchAsyncValveConnectorGridsEdit';
import { fetchAsyncValveConnectorsEdit } from './thunks/fetchAsyncValveConnectorsEdit';
import { fetchAsyncValveGridsEdit } from './thunks/fetchAsyncValveGridsEdit';
import { articleEditAdapter } from './adapters/articleEditAdapter';
import { fetchAsyncArticleEdit } from './thunks/fetchAsyncArticleEdit';
import { fetchAsyncLanguageEdit } from './thunks/fetchAsyncLanguageEdit';
import { languageEditAdapter } from './adapters/languageEditAdapter';
import { fetchAsyncEppTerminalEdit } from './thunks/fetchAsyncEppTerminalEdit';
import { eppTerminalEditAdapter } from './adapters/eppTerminalEditAdapter';
import { fetchAsyncEppTerminalTypeEdit } from './thunks/fetchAsyncEppTerminalTypeEdit';
import { eppTerminalTypeAdapter } from 'application/components/redux/adapters/eppTerminalTypeAdapter';
//  functions

export const editcompSlice = createSlice({
  name: 'editcomp',
  initialState,
  reducers: {
    Refresh(state, action) {
      // ✅ This "mutating" code is okay inside of createSlice!
      state.valveConnectorGrids.loading = 'init';
      state.valveConnectors.loading = 'init';
      state.valveGrids.loading = 'init';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAsyncLanguageEdit.pending, (state, action) => {
        state.languages.loading = 'loading';
      })
      .addCase(fetchAsyncLanguageEdit.fulfilled, (state, action) => {
        languageEditAdapter.setAll(state.languages, action.payload);
        state.languages.loading = 'idle';
      })
      .addCase(fetchAsyncArticleEdit.pending, (state, action) => {
        state.articles.loading = 'loading';
      })
      .addCase(fetchAsyncArticleEdit.fulfilled, (state, action) => {
        articleEditAdapter.setAll(state.articles, action.payload);
        state.articles.loading = 'idle';
      })
      .addCase(fetchAsyncEppTerminalEdit.pending, (state, action) => {
        state.eppTerminals.loading = 'loading';
      })
      .addCase(fetchAsyncEppTerminalEdit.fulfilled, (state, action) => {
        eppTerminalEditAdapter.setAll(state.eppTerminals, action.payload);
        state.eppTerminals.loading = 'idle';
      })
      .addCase(fetchAsyncEppTerminalTypeEdit.pending, (state, action) => {
        state.eppTerminalTypes.loading = 'loading';
      })
      .addCase(fetchAsyncEppTerminalTypeEdit.fulfilled, (state, action) => {
        eppTerminalTypeAdapter.setAll(state.eppTerminalTypes, action.payload);
        state.eppTerminalTypes.loading = 'idle';
      })
      .addCase(fetchAsyncValveConnectorGridsEdit.pending, (state, action) => {
        state.valveConnectorGrids.loading = 'loading';
      })
      .addCase(fetchAsyncValveConnectorGridsEdit.fulfilled, (state, action) => {
        valveConnectorGridEditAdapter.setAll(state.valveConnectorGrids, action.payload);
        state.valveConnectorGrids.loading = 'idle';
      })
      .addCase(fetchAsyncValveConnectorsEdit.pending, (state, action) => {
        state.valveConnectors.loading = 'loading';
      })
      .addCase(fetchAsyncValveConnectorsEdit.fulfilled, (state, action) => {
        valveConnectorEditAdapter.setAll(state.valveConnectors, action.payload);
        state.valveConnectors.loading = 'idle';
      })
      .addCase(fetchAsyncValveGridsEdit.pending, (state, action) => {
        state.valveGrids.loading = 'loading';
      })
      .addCase(fetchAsyncValveGridsEdit.fulfilled, (state, action) => {
        valveGridEditAdapter.setAll(state.valveGrids, action.payload);
        state.valveGrids.loading = 'idle';
      });
  },
});

export default editcompSlice.reducer;
