import axios from 'axios';
import bearerTokenHeader from 'components/api-authorization/bearerToken';

export interface IEppTerminalTypeEdit {
  eppTerminalTypeID: number;
  name: string;
  isDeleted: boolean;
}
export const getEppTerminalTypeEdit = async () => {
  const config = await bearerTokenHeader();
  const response = await axios.get('api/EppTerminalType/edit', config);
  return response.data;
};
