import RegularPage from 'components/controls/RegularPage';
import { InfoDialogEx } from 'components/dialog/InfoDialog';
import useInfoDialog, { OpenInfoDialogProps } from 'components/dialog/useInfoDialog';
import React from 'react';
import { useGridStateData } from '../controls/hooks/useGridStateData';
import useArticleGridData, { IArticleEditEx } from './useArticleEdit';
import ReactEditTable, { booleanFilterFn, languageColumns } from 'components/table/ReactEditTable';
import { createColumnHelper } from '@tanstack/react-table';
import DataCheckBox from 'components/table/DataCheckBox';

const columnHelper = createColumnHelper<IArticleEditEx>();

export interface IGridStateData {
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void;
}

export const initExpandedData = (
  openInfoDialog: (openInfoDialogProps: OpenInfoDialogProps) => void
): IGridStateData => {
  return {
    openInfoDialog: openInfoDialog,
  };
};

const ArticleEdit = () => {
  const data = useArticleGridData();
  const [infoDialogState, openInfoDialog] = useInfoDialog();
  const gridStatePlaceHolder = useGridStateData(initExpandedData(openInfoDialog));

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('articleID', {
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('isDeleted', {
        id: 'isDeleted',
        header: 'deleted',
        cell: (info) => <DataCheckBox value={info.getValue()} preventChange />,
        filterFn: booleanFilterFn,
      }),
      columnHelper.accessor('number', {
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor('name', {
        cell: (info) => info.getValue(),
      }),
      ...languageColumns(data.languages, columnHelper),
    ],
    [data.languages]
  );

  return (
    <RegularPage title="Articles">
      <InfoDialogEx {...infoDialogState} />
      <div>Articles</div>
      <ReactEditTable
        state={gridStatePlaceHolder}
        data={data.articles}
        editUpdated={data.articleEditUpdated}
        columns={columns}
      />
    </RegularPage>
  );
};

export default ArticleEdit;
