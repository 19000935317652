import { getExtraComponentImage } from 'application/components/api/Image';
import EditorPage from 'components/controls/EditorPage';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ProjectExtraComponent, { useProjectExtraComponentStyles } from './ProjectExtraComponent';
import { useExtraComponents } from './useExtraComponents';

const ExtraComponents = (props: { onNext: () => any }) => {
  const classes = useProjectExtraComponentStyles();
  const [extraComponentsGroups] = useExtraComponents();
  const { t } = useTranslation();

  console.log('ExtraComponents', { extraComponentsGroups });
  return (
    <EditorPage title={t('extracomponent_title', 'Extra components')} onNext={props.onNext}>
      {extraComponentsGroups.map((extraComponentsGroup) => (
        <div key={extraComponentsGroup.key} className={classes.group}>
          <div className={classes.ComponentContainer}>
            {extraComponentsGroup.items.map((screenComponent) => (
              <ProjectExtraComponent
                key={screenComponent.extraComponent.extraComponentID}
                extraComponent={screenComponent.extraComponent}
                projectExtraComponent={screenComponent.projectExtraComponent}
              />
            ))}
          </div>
          <div className={classes.preImageContainer}>
            <div className={classes.imageContainer}>
              <div className={classes.centered}></div>
              <img src={getExtraComponentImage(extraComponentsGroup.imageID)} className={classes.image} alt="" />
            </div>
          </div>
        </div>
      ))}
    </EditorPage>
  );
};

export default ExtraComponents;
