import { IEppColor } from 'application/components/api/EppColor';
import { IEppDiameter } from 'application/components/api/EppDiameter';
import { IEppTerminalType } from 'application/components/api/EppTerminalType';
import { selectEppColorEntities } from 'application/components/redux/selectors/eppColorSelector';
import { selectEppDiameterEntities } from 'application/components/redux/selectors/eppDiameterSelector';
import { fetchAsyncEppColors } from 'application/components/redux/thunks/fetchAsyncEppColors';
import { fetchAsyncEppDiameters } from 'application/components/redux/thunks/fetchAsyncEppDiameters';
import { IArticleEdit } from 'application/editcomp/api/Article';
import { getEppTerminalEdit, IEppTerminalEdit } from 'application/editcomp/api/EppTerminal';
import { selectArticleEditEntities } from 'application/editcomp/redux/selectors/articleEditSelector';
import { selectEppTerminalTypeEditEntities } from 'application/editcomp/redux/selectors/eppTerminalTypeSelector';
import { fetchAsyncArticleEdit } from 'application/editcomp/redux/thunks/fetchAsyncArticleEdit';
import { fetchAsyncEppTerminalTypeEdit } from 'application/editcomp/redux/thunks/fetchAsyncEppTerminalTypeEdit';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

export interface IEppTerminalEditEx extends IEppTerminalEdit {
  eppTerminalType: IEppTerminalType;
  eppColor: IEppColor;
  eppDiameter: IEppDiameter;
  article: IArticleEdit;
  adapter: IEppTerminalEdit;
  adapterArticle: IArticleEdit;
  roofArticle: IArticleEdit;
  roofConnectArticle: IArticleEdit;
}
const useEppTerminalGridData = () => {
  const dispatch = useDispatch();
  const [eppTerminals, setEppTerminals] = React.useState<IEppTerminalEdit[]>([]);

  React.useEffect(() => {
    dispatch(fetchAsyncEppTerminalTypeEdit({}));
    dispatch(fetchAsyncEppColors({}));
    dispatch(fetchAsyncEppDiameters({}));
    dispatch(fetchAsyncArticleEdit({}));
    getEppTerminalEdit().then((result) => setEppTerminals(result));
  }, [dispatch]);

  const eppTerminalUpdated = React.useCallback(
    (item: IEppTerminalEdit) => {
      setEppTerminals((items) =>
        items.map((prevItem) => (prevItem.eppTerminalID === item.eppTerminalID ? item : prevItem))
      );
    },
    [setEppTerminals]
  );

  const eppTerminalTypeEntities = useSelector(selectEppTerminalTypeEditEntities);
  const eppColorEntities = useSelector(selectEppColorEntities);
  const eppDiameterEntities = useSelector(selectEppDiameterEntities);
  const articleEditEntities = useSelector(selectArticleEditEntities);

  return React.useMemo(
    () => ({
      eppTerminals: eppTerminals.map(
        (item) =>
          ({
            ...item,
            eppTerminalType: eppTerminalTypeEntities[item.eppTerminalTypeID],
            eppColor: eppColorEntities[item.eppColorID],
            eppDiameter: eppDiameterEntities[item.eppDiameterID],
            article: articleEditEntities[item.articleID],
            roofArticle: articleEditEntities[item.roofArticleID],
            roofConnectArticle: articleEditEntities[item.roofConnectArticleID],
            adapter: item.adapterID ? eppTerminals.find((a) => a.adapterID === item.adapterID) : undefined,
            adapterArticle: item.adapterID
              ? articleEditEntities[eppTerminals.find((a) => a.adapterID === item.adapterID)?.articleID || 0]
              : undefined,
          } as IEppTerminalEditEx)
      ),
      eppTerminalUpdated,
    }),
    [
      eppTerminals,
      eppTerminalTypeEntities,
      eppColorEntities,
      eppDiameterEntities,
      articleEditEntities,
      eppTerminalUpdated,
    ]
  );
};

export default useEppTerminalGridData;
