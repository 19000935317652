import * as React from 'react';
import clsx from 'clsx';
import { tableStyles } from './tableStyles';

export interface ICrudOrderBy {
  field: string;
  desc: boolean;
  getOrderByDescription?: (item: any) => string;
}

export interface ISortingFields {
  onClick: (
    name: string,
    getOrderByDescription: ((item: any) => string) | ((item: any) => number),
    event: React.MouseEvent<Element>
  ) => any;
  currentOrderBy?: string;
  orderByFields?: ICrudOrderBy[];
  getOrderByDescription?: ((item: any) => string) | ((item: any) => number);
}
export interface IThProps {
  className?: string;
  children?: any;
  colSpan?: number;
  rowSpan?: number;
  style?: React.CSSProperties;
  title?: string;
  firstRow?: boolean;
  firstCell?: boolean;
  key?: string | number;
  OnWidthChanged?: (width: number) => any;
  OnHeightChanged?: (height: number) => any;
  onClick?: (name: string | undefined) => any;
  hoveredChanged?: (value: boolean) => any;
  dataField?: string;
  sortingFields?: ISortingFields;

  stickyRowTop?: number;
  stickyColumnLeft?: number;

  borderLeft?: string;
  borderRight?: string;
  borderBottom?: string;
  borderTop?: string;
  textAlign?: 'left' | 'center' | 'right';
}

const Th = (props: IThProps) => {
  const tableClasses = tableStyles();
  const cell = React.useRef();

  let borderLeft = props.borderLeft;
  let borderRight = props.borderRight;
  let borderBottom = props.borderBottom;
  let borderTop = props.borderTop;

  if (borderTop === undefined) {
    if (props.firstCell) {
      borderTop = tableClasses.BorderTopBold;
    }
  }
  if (borderLeft === undefined) {
    if (props.firstCell) {
      borderLeft = tableClasses.BorderLeftBold;
    }
  }
  if (borderRight === undefined) borderRight = tableClasses.BorderRightBold;
  if (borderBottom === undefined) borderBottom = tableClasses.BorderBottomBold;

  const [, setIsHovered] = React.useState(false);
  const handleMouseEnter = () => {
    if (props.hoveredChanged) {
      setIsHovered(true);
      props.hoveredChanged(true);
    }
  };
  const handleMouseLeave = () => {
    if (props.hoveredChanged) {
      setIsHovered(false);
      props.hoveredChanged(false);
    }
  };
  React.useEffect(() => {});
  const style: any = { ...props.style };

  if (props.textAlign) {
    style.textAlign = props.textAlign;
  }

  if (props.stickyRowTop !== undefined) {
    style.top = props.stickyRowTop;
    style.position = 'sticky';
    style.zIndex = 5;
  }

  if (props.stickyColumnLeft !== undefined) {
    style.left = props.stickyColumnLeft;
    style.position = 'sticky';
    style.zIndex = 4;
  }

  if (props.stickyRowTop !== undefined && props.stickyColumnLeft !== undefined) {
    style.zIndex = 6;
  }

  let newProps: any = { ...props };
  delete newProps.borderTop;
  delete newProps.borderLeft;
  delete newProps.borderBottom;
  delete newProps.borderRight;
  delete newProps.stickyRowTop;
  delete newProps.stickyColumnLeft;
  delete newProps.OnHeightChanged;
  delete newProps.OnWidthChanged;
  delete newProps.textAlign;
  delete newProps.onClick;
  delete newProps.dataField;
  delete newProps.currentOrderBy;
  delete newProps.sortingFields;
  delete newProps.getOrderByDescription;
  delete newProps.firstCell;

  const { sortingFields, dataField, onClick: onClickProps } = props;
  const { orderByFields, currentOrderBy } = sortingFields || {};
  const orderBy = React.useMemo(() => {
    const orderField = orderByFields?.find((a) => a.field === dataField);
    if ((dataField && dataField === currentOrderBy) || (orderField && orderField.desc === false)) {
      return <span>▼</span>;
    } else if (
      (dataField && currentOrderBy && currentOrderBy.startsWith(dataField)) ||
      (orderField && orderField.desc === true)
    ) {
      return <span>▲</span>;
    } else {
      return undefined;
    }
  }, [dataField, currentOrderBy, orderByFields]);

  return (
    <th
      {...newProps}
      ref={cell}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={React.useCallback(
        (event: any) => {
          onClickProps && onClickProps(dataField);
        },
        [onClickProps, dataField]
      )}
      style={style}
      className={clsx(
        tableClasses.tableBorder,
        tableClasses.cellPadding,
        tableClasses.verticalAlignBottom,
        props.stickyRowTop !== undefined ? tableClasses.thead : null,
        borderLeft,
        borderBottom,
        borderRight,
        onClickProps ? 'clickable' : null,
        props.className
      )}>
      {props.children}
      {orderBy}
    </th>
  );
};

export default Th;
