import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConnectionEdit from './ConnectionEditor/ConnectionEdit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { makeSelectConnectionsByDirection } from 'application/project/redux/selectors/connectionSelectors';
import { projectSlice } from 'application/project/redux/projectSlice';
import { FormatDecimal } from 'application/shared/format';
import PageTitle from 'components/controls/PageTitle';
import { useGlobalStyles } from 'application/shared/styles';
import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Redirect } from 'react-router-dom';
import { pages_project_extractionplenum, pages_project_supplyplenum } from '../EditorGrid/useRoutes';
import { useFlowRate } from 'components/controls/useFlowRate';
import { selectPlenumById } from 'application/components/redux/selectors/plenumSelector';
import { ValveConnectionAdd } from 'application/project/redux/thunks/ValveConnectionAdd';
import useIsConnectionValid from './ConnectionEditor/useConnectionValid';
import { selectProjectState } from 'redux/store';
import { createPlenumDataId } from 'application/project/redux/adapters/plenumDataAdapter';
import { selectPlenumdataById } from 'application/project/redux/selectors/plenumdataSelectors';

const useRoomEditStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonGrid: {
      marginTop: theme.spacing(2),
    },
    button: {
      marginRight: theme.spacing(2),
    },
  })
);

export const RoomEditor = (props: { supply: PlenumDataSupply; plenumIndex: number; onNext: () => any }) => {
  const dispatch = useDispatch();
  const selectConnectionsByDirection = React.useMemo(makeSelectConnectionsByDirection, []);
  const connections = useSelector((state) => selectConnectionsByDirection(state, props.supply, props.plenumIndex));
  const projectState = useSelector(selectProjectState);
  const [connectionValid, setConnectionValid] = React.useState<any>({});
  const plenumdata = useSelector((state) =>
    selectPlenumdataById(state, createPlenumDataId({ supply: props.supply, index: props.plenumIndex }))
  );
  const plenum = useSelector((state) => selectPlenumById(state, plenumdata?.plenumID ?? 0));

  const classes = useGlobalStyles();
  const editstyle = useRoomEditStyles();
  const { FormatAirFlowRateUnit } = useFlowRate();
  const activeValid = plenumdata?.activeConnectionKey
    ? (connectionValid as any)[plenumdata.activeConnectionKey]
    : undefined;

  const getConnectionError = useIsConnectionValid(plenumdata || { supply: props.supply, index: props.plenumIndex });

  const canAddNew = React.useMemo(() => {
    if (connections.length === 0) return true;
    const hasConnectionError = connections.reduce((hasError, connection) => {
      const error = getConnectionError(connection, projectState);
      return activeValid !== false && (hasError ? hasError : !!error);
    }, false);
    return !hasConnectionError;
  }, [activeValid, projectState, connections, getConnectionError]);

  const canSetActiveNone = React.useMemo(() => {
    if (!plenumdata?.activeConnectionKey) return false;
    const activeConnection = connections.find((a) => a.valveConnectionKey === plenumdata?.activeConnectionKey);
    return activeValid !== false && activeConnection && !!getConnectionError(activeConnection, projectState) === false;
  }, [activeValid, projectState, connections, plenumdata, getConnectionError]);

  const { t } = useTranslation(['roomeditor']);

  const title = React.useMemo(
    () =>
      t(
        'title_roomedit_' + (props.supply === PlenumDataSupply.supply ? 'supply' : 'extraction'),
        'Edit rooms ' + (props.supply === PlenumDataSupply.supply ? 'supply' : 'extraction')
      ),
    [props.supply, t]
  );

  const setActive = (key: string) => {
    const active = connections.find((a) => a.valveConnectionKey === plenumdata?.activeConnectionKey);
    if (activeValid !== false && (active === undefined || !!getConnectionError(active, projectState) === false)) {
      if (connections)
        dispatch(
          projectSlice.actions.setActiveConnection({
            supply: props.supply,
            index: props.plenumIndex,
            valveConnectionKey: key,
          })
        );
    }
  };

  const setValid = (key: string, valid: boolean) => {
    setConnectionValid((prev: any) => ({ ...prev, [key]: valid }));
  };

  const clearActiveConnection = React.useCallback(
    () =>
      dispatch(
        projectSlice.actions.setActiveConnection({
          supply: props.supply,
          index: props.plenumIndex,
          valveConnectionKey: undefined,
        })
      ),
    [dispatch, props.supply, props.plenumIndex]
  );

  const connectionAdded = React.useCallback(
    () => dispatch(ValveConnectionAdd({ supply: props.supply, plenumIndex: props.plenumIndex })),
    [dispatch, props.supply, props.plenumIndex]
  );

  if (plenumdata && !plenumdata?.plenumID) {
    const location = props.supply ? pages_project_supplyplenum : pages_project_extractionplenum;
    return <Redirect to={location} push={false} />;
  } else
    return (
      <div className={classes.roomContent}>
        <PageTitle title={title} onNext={props.onNext} extrapadding />
        <div>
          {plenumdata
            ? connections.map((connection) => (
                <ConnectionEdit
                  key={connection.valveConnectionKey}
                  connection={connection}
                  plenumdata={plenumdata}
                  setActive={setActive}
                  setValid={setValid}
                  isActive={plenumdata.activeConnectionKey === connection.valveConnectionKey}
                  isValid={(connectionValid as any)[connection.valveConnectionKey]}
                  getConnectionError={getConnectionError}
                />
              ))
            : null}
        </div>
        <div className={clsx(classes.regularContentWidth, editstyle.buttonGrid)}>
          <div>
            <Button
              variant="contained"
              color="primary"
              className={editstyle.button}
              title={t('addconnection', 'add new connection')}
              onClick={connectionAdded}
              disabled={!canAddNew}>
              <AddCircleIcon />
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={editstyle.button}
              title={t('savechanges', 'save changes')}
              onClick={clearActiveConnection}
              disabled={!canSetActiveNone}>
              <CheckCircleIcon />
            </Button>
          </div>
          <div>
            {t('working pressure', 'working pressure {{workPresure}} Pa', {
              workPresure: FormatDecimal(plenumdata?.workPresure, 1),
            })}
            <br />
            {t('totalairflowrate', 'Total airflowrate {{airFlowRate}}, max {{maxFlowrate}}', {
              airFlowRate: FormatAirFlowRateUnit(plenumdata?.airflowRate),
              maxFlowrate: FormatAirFlowRateUnit(plenum?.maxFlowRate),
            })}
          </div>
        </div>
      </div>
    );
};

export default RoomEditor;
