import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ComponentList from '../ComponentList/ComponentList';
import EppDataEdit from '../EppDataEdit/EppDataEdit';
import ExtraComponents from '../ExtraComponents/ExtraComponents';
import InstallationPlan from '../InstallationPlan/InstallationPlan';
import { PlenumSelector } from '../PlenumSelector/PlenumSelector';
import ProjectSettings from '../ProjectSettings/ProjectSettings';
import RoomEditor from '../RoomEditor/RoomEditor';

import additionalComponents from 'image/menu/Icon-Additional components.svg';
import airExtraction from 'image/menu/Icon-Air extraction.svg';
import airSupply from 'image/menu/Icon-Air supply.svg';
import componentList from 'image/menu/Icon-Component list.svg';
import installationList from 'image/menu/Icon-Installation list.svg';
import projectSettings from 'image/menu/Icon-Project settings.svg';
import startProject from 'image/menu/Icon-Start project.svg';

import a1png from 'image/overview/A1.png';
import a2png from 'image/overview/A2.png';
import a3png from 'image/overview/A3.png';
import a4png from 'image/overview/A4.png';
import a5png from 'image/overview/A5.png';
import a6png from 'image/overview/A6.png';
import a7png from 'image/overview/A7.png';
//import a8png from 'image/overview/A8.png'; //wtw unit nog niet
import a9png from 'image/overview/A9.png';

import b1png from 'image/overview/B1.png';
import b2png from 'image/overview/B2.png';
import b3png from 'image/overview/B3.png';
//import b4png from 'image/overview/B4.png'; //wtw unit nog niet
import b5png from 'image/overview/B5.png';

import { useTranslation } from 'react-i18next';
import { IEditorTabs } from './IEditorTabs';
import { useSelector } from 'react-redux';
import { selectActiveProject } from 'application/project/redux/selectors/projectSelector';

export const pages_project_list = '/project/list';
export const pages_project_setactive = '/project/setactive/';
export const pages_project_edit = '/project/edit';
export const pages_project_supplyplenum = '/project/edit/supply';
export const pages_project_room = '/room';
export const pages_project_mvhrplenum = '/mvhrplenum';
export const pages_project_outmvhr = '/outmvhr';
export const pages_project_extractionplenum = '/project/edit/extraction';
export const pages_project_extracomponents = '/project/edit/extracomponents';
export const pages_project_componentlist = '/project/edit/componentlist';
export const pages_project_installationplan = '/project/edit/installationplan';
export const pages_project_calculation = '/project/edit/calculation';

interface IUseRoutes {
  populatedEditorTabs: IEditorTabs[];
  editorTabs: IEditorTabs[][];
  currentIndex: number;
  image: string | undefined;
  onNext: () => any;
  canNext: boolean;
  onPrev: () => any;
  canPrev: boolean;
}
export const useRoutes = (extraction: boolean, roles: string[]): IUseRoutes => {
  const { t } = useTranslation(['editortabs']);
  const location = useLocation();
  const history = useHistory();

  const project = useSelector(selectActiveProject);
  const supplyPlenumCount: number = project?.supplyPlenums || 0;
  const extractionPlenumCount: number = project?.extractionPlenums || 0;

  const theEditorTabs: IEditorTabs[] = React.useMemo(() => {
    const supplyPlenums: IEditorTabs[] = Array.from(Array(supplyPlenumCount).keys()).flatMap((index) => {
      const indexNr = supplyPlenumCount > 1 ? ` ${index + 1}` : '';
      const tabs = [
        {
          title: t('airsupply', 'Air supply') + indexNr,
          url: pages_project_supplyplenum,
          path: '',
          exact: true,
          component: undefined,
          icon: airSupply,
          supply: true,
          balansimage: a2png,
          plenumIndex: index,
        },
        {
          title: t('airsupplyrooms', 'Rooms for air supply'),
          url: pages_project_room,
          path: '',
          exact: true,
          urlGroup: pages_project_supplyplenum + `/${index}`,
          component: undefined,
          supply: true,
          balansimage: a3png,
          plenumIndex: index,
        },
        {
          title: t('airsupplymvhrplenum', 'Duct component between plenum & MVHR for air supply'),
          url: pages_project_mvhrplenum,
          path: '',
          exact: true,
          urlGroup: pages_project_supplyplenum + `/${index}`,
          component: undefined,
          supply: true,
          balansimage: a4png,
          plenumIndex: index,
        },
      ];
      if (index === 0) {
        tabs.push({
          title: t('airsupplyoutmvhr', 'Duct component between MVHR & roof/wall terminal for air supply'),
          url: pages_project_outmvhr,
          path: '',
          exact: true,
          urlGroup: pages_project_supplyplenum + `/${index}`,
          component: undefined,
          supply: true,
          balansimage: a9png,
          plenumIndex: index,
        });
      }
      return tabs;
    });
    const extractionPlenums: IEditorTabs[] = Array.from(Array(extractionPlenumCount).keys()).flatMap((index) => {
      const indexNr = extractionPlenumCount > 1 ? ' ' + (index + 1) : '';
      const tabs = [
        {
          title: t('airextraction', 'Air extraction') + indexNr,
          url: pages_project_extractionplenum,
          path: '',
          exact: true,
          component: undefined,
          icon: airExtraction,
          balansimage: a6png,
          extractimage: b2png,
          plenumIndex: index,
        },
        {
          title: t('airextractionrooms', 'Rooms for air extraction'),
          url: pages_project_room,
          path: '',
          exact: true,
          urlGroup: pages_project_extractionplenum,
          component: undefined,
          balansimage: a5png,
          extractimage: b1png,
          plenumIndex: index,
        },
        {
          title: t('airextractionplenummvhr', 'Duct component between plenum & MVHR for air extraction'),
          url: pages_project_mvhrplenum,
          path: '',
          exact: true,
          urlGroup: pages_project_extractionplenum,
          component: undefined,
          balansimage: a7png,
          extractimage: b3png,
          plenumIndex: index,
        },
      ];
      if (index === 0) {
        tabs.push({
          title: t('airextractionmvhrout', 'Duct component between MVHR & roof/wall terminal for air extraction'),
          url: pages_project_outmvhr,
          path: '',
          exact: true,
          urlGroup: pages_project_extractionplenum,
          component: undefined,
          balansimage: a1png,
          extractimage: b5png,
          plenumIndex: index,
        });
      }
      return tabs;
    });
    const editorTabs = [
      {
        title: t('projectsettings', 'Project settings'),
        url: pages_project_edit,
        path: '',
        exact: true,
        component: undefined,
        icon: projectSettings,
      },
      ...supplyPlenums,
      ...extractionPlenums,
      {
        title: t('extracomponents', 'Additional components'),
        url: pages_project_extracomponents,
        path: '',
        component: undefined,
        icon: additionalComponents,
      },
      {
        title: t('componentlist', 'Components list'),
        url: pages_project_componentlist,
        path: '',
        component: undefined,
        icon: componentList,
      },
      {
        title: t('installationplan', 'Installation plan'),
        url: pages_project_installationplan,
        path: '',
        component: undefined,
        icon: installationList,
      },
      {
        title: 'Calculation report',
        url: pages_project_calculation,
        path: '',
        component: undefined,
        icon: installationList,
        role: 'Calculator',
      },
    ];
    return editorTabs
      .filter((a) => (!extraction || a.supply !== true) && (!a.role || roles.includes(a.role)))
      .map((item) => {
        return {
          ...item,
          path:
            item.plenumIndex !== undefined && item.urlGroup === undefined
              ? item.url + `/${item.plenumIndex}`
              : item.plenumIndex !== undefined && item.urlGroup !== undefined
              ? item.urlGroup + item.url
              : item.url,
        };
      });
  }, [t, supplyPlenumCount, extractionPlenumCount, extraction, roles]);
  const theEditorTabsRef = React.useRef(theEditorTabs);
  theEditorTabsRef.current = theEditorTabs;

  const listEditorTabs = React.useMemo(
    () =>
      [
        {
          title: t('projectlist', 'Project list'),
          url: pages_project_list,
          icon: startProject,
          path: pages_project_list,
        },
      ] as IEditorTabs[],
    [t]
  );

  const editorTabs = React.useMemo(() => [listEditorTabs, theEditorTabs], [theEditorTabs, listEditorTabs]);

  const currentIndex = React.useMemo(
    () => theEditorTabsRef.current.findIndex((a) => a.path.toLowerCase() === location.pathname.toLowerCase()),
    [location.pathname]
  );

  const onNext = React.useCallback(() => {
    history.replace(theEditorTabsRef.current[currentIndex + 1].path);
  }, [currentIndex, history]);
  const canNext = React.useMemo(() => currentIndex < theEditorTabsRef.current.length - 1, [currentIndex]);
  const onPrev = React.useCallback(() => {
    history.replace(theEditorTabsRef.current[currentIndex - 1].path);
  }, [currentIndex, history]);
  const canPrev = React.useMemo(() => currentIndex > 0, [currentIndex]);

  const populatedEditorTabs = React.useMemo(() => {
    theEditorTabs.forEach((item) => {
      const supply = item.supply === true ? PlenumDataSupply.supply : PlenumDataSupply.extraction;
      const plenumIndex = item.plenumIndex || 0;
      switch (item.url) {
        case pages_project_edit:
          item.component = <ProjectSettings onNext={onNext} />;
          break;
        case pages_project_supplyplenum:
        case pages_project_extractionplenum:
          item.component = <PlenumSelector supply={supply} index={plenumIndex} onNext={onNext} />;
          break;
        case pages_project_room:
          item.component = <RoomEditor supply={supply} plenumIndex={plenumIndex} onNext={onNext} />;
          break;
        case pages_project_mvhrplenum:
          item.component = (
            <EppDataEdit supply={supply} inside={PlenumDataInside.inside} plenumIndex={plenumIndex} onNext={onNext} />
          );
          break;
        case pages_project_outmvhr:
          item.component = (
            <EppDataEdit supply={supply} inside={PlenumDataInside.outside} plenumIndex={plenumIndex} onNext={onNext} />
          );
          break;
        case pages_project_extracomponents:
          item.component = <ExtraComponents onNext={onNext} />;
          break;
        case pages_project_componentlist:
          item.component = <ComponentList onNext={onNext} />;
          break;
        case pages_project_installationplan:
          item.component = <InstallationPlan onNext={onNext} />;
          break;
        case pages_project_calculation:
          item.component = <InstallationPlan calculation onNext={onNext} />;
          break;
        default:
          item.component = <div>??</div>;
          break;
      }
    });
    return theEditorTabs;
  }, [theEditorTabs, onNext]);

  return {
    populatedEditorTabs,
    editorTabs,
    currentIndex,
    image:
      currentIndex >= 0
        ? extraction
          ? populatedEditorTabs[currentIndex].extractimage
          : populatedEditorTabs[currentIndex].balansimage
        : undefined,
    onNext,
    canNext,
    onPrev,
    canPrev,
  };
};
