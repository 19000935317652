import { createSelector } from '@reduxjs/toolkit';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectProjectState } from 'redux/store';
import { valveConnectionAdapter } from '../adapters/valveConnectionAdapter';

export const {
  selectAll: selectAllValveConnections,
  selectById: selectValveConnectionByKey,
  selectIds: selectValveConnectionIds,
  selectEntities: selectValveConnectionEntities,
} = valveConnectionAdapter.getSelectors((state) => selectProjectState(state).valveConnections);

export const makeSelectConnectionsByDirection = () => {
  return createSelector(
    (state: any, supply: PlenumDataSupply, plenumIndex: number) => supply,
    (state: any, supply: PlenumDataSupply, plenumIndex: number) => plenumIndex,
    // First, pass one or more "input selector" functions:
    selectAllValveConnections,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (supply, plenumIndex, connections) =>
      connections.filter((connection) => connection.supply === supply && connection.plenumIndex === plenumIndex)
  );
};
