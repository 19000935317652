import * as React from 'react';
import OptimisticCheckBox from './OptimisticCheckBox';

interface IFilterTextProps {
  value?: boolean;
  title?: string;
  isTriState?: boolean;
  preventChange?: boolean;
  onChange?: (value: boolean | undefined) => void;
}

const DataCheckBox = (props: IFilterTextProps) => {
  return (
    <OptimisticCheckBox
      title={props.title}
      active={props.value}
      isTriState={props.isTriState === undefined ? true : props.isTriState}
      preventChange={props.preventChange}
      onChange={(active: boolean | undefined) => (props.onChange ? props.onChange(active) : {})}
    />
  );
};

export default DataCheckBox;
