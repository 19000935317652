import * as React from 'react';
import { IEppColor } from 'application/components/api/EppColor';
import { IEppDiameter } from 'application/components/api/EppDiameter';
import { IEppMaterial } from 'application/components/api/EppMaterial';
import { IEppTerminalType } from 'application/components/api/EppTerminalType';
import { IEppData } from 'application/project/api/EppData';
import { PlenumDataInside, PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import EditorPage from 'components/controls/EditorPage';
import { RadioSelector } from 'components/controls/RadioSelector';
import nameof from 'ts-nameof.macro';
import EppComponent from './EppComponent';
import { useEppData } from './useEppData';
import ExtraResistant from './ExtraResistant';
import { useTranslation } from 'react-i18next';
import { IEppDamperType } from 'application/components/api/EppDamperTerminal';

export const EppDataEdit = (props: {
  supply: PlenumDataSupply;
  inside: PlenumDataInside;
  plenumIndex: number;
  onNext: () => any;
}) => {
  const [
    title,
    editEppData,
    screenMaterial,
    screenDiameter,
    screenTerminalTypes,
    screenDamperTypes,
    screenColors,
    screenComponents,
    onChange,
  ] = useEppData(props);
  const { t } = useTranslation(['eppedit']);

  if (!editEppData) return <div></div>;
  return (
    <EditorPage title={title} onNext={props.onNext}>
      <RadioSelector
        label={t('material', 'material')}
        name={nameof<IEppData>((o) => o.eppMaterialID)}
        value={editEppData.eppMaterialID}
        onChange={onChange}
        valueFieldName={nameof<IEppMaterial>((o) => o.eppMaterialID)}
        displayFieldName={nameof<IEppMaterial>((o) => o.name)}
        options={screenMaterial}
      />
      <RadioSelector
        label={t('diameter', 'diameter')}
        name={nameof<IEppData>((o) => o.eppDiameterID)}
        value={editEppData.eppDiameterID}
        onChange={onChange}
        valueFieldName={nameof<IEppDiameter>((o) => o.eppDiameterID)}
        displayFieldName={nameof<IEppDiameter>((o) => o.name)}
        options={screenDiameter}
      />
      {props.inside === PlenumDataInside.outside ? (
        <React.Fragment>
          <RadioSelector
            label={t('terminal', 'terminal')}
            name={nameof<IEppData>((o) => o.eppTerminalTypeID)}
            value={editEppData?.eppTerminalTypeID ? editEppData.eppTerminalTypeID : 0}
            onChange={onChange}
            valueFieldName={nameof<IEppTerminalType>((o) => o.eppTerminalTypeID)}
            displayFieldName={nameof<IEppTerminalType>((o) => o.name)}
            options={screenTerminalTypes}
          />
          <RadioSelector
            label={t('color', 'color')}
            name={nameof<IEppData>((o) => o.eppColorID)}
            value={editEppData.eppColorID}
            onChange={onChange}
            valueFieldName={nameof<IEppColor>((o) => o.eppColorID)}
            displayFieldName={nameof<IEppDiameter>((o) => o.name)}
            options={screenColors}
          />
        </React.Fragment>
      ) : (
        <RadioSelector
          label={t('damper', 'damper')}
          name={nameof<IEppData>((o) => o.eppDamperTypeID)}
          value={editEppData?.eppDamperTypeID ? editEppData.eppDamperTypeID : 0}
          onChange={onChange}
          valueFieldName={nameof<IEppDamperType>((o) => o.eppDamperTypeID)}
          displayFieldName={nameof<IEppDamperType>((o) => o.name)}
          options={screenDamperTypes}
        />
      )}
      <div style={{ display: 'inline-block' }}>
        {screenComponents.map((screenComponent) => (
          <div key={screenComponent.component.eppTypeID}>
            <EppComponent
              eppDataComponent={screenComponent.eppDataComponent}
              component={screenComponent.component}
              eppData={editEppData}
            />
          </div>
        ))}
      </div>
      {props.inside === PlenumDataInside.inside ? (
        <div style={{ display: 'inline-block', verticalAlign: 'top', marginLeft: 16 }}>
          <ExtraResistant eppData={editEppData} onChange={onChange} />
        </div>
      ) : null}
    </EditorPage>
  );
};

export default EppDataEdit;
