import { IExtraComponent } from 'application/components/api/ExtraComponent';
import { selectAllExtraComponents } from 'application/components/redux/selectors/extraComponentSelector';
import { IProjectExtraComponent } from 'application/project/api/ProjectExtraComponent';
import { selectAllProjectExtraComponents } from 'application/project/redux/selectors/projectExtraComponentSelectors';
import React from 'react';
import { useSelector } from 'react-redux';

export interface IProjectExtraComponentRefVal {
  extraComponent: IExtraComponent;
  projectExtraComponent: IProjectExtraComponent | undefined;
}

export interface IProjectExtraComponentGroupRefVal {
  key: number;
  imageID: number;
  extraComponentGroupID?: number;
  sort: number;
  items: IProjectExtraComponentRefVal[];
}

export const useExtraComponents = (): [IProjectExtraComponentGroupRefVal[]] => {
  const extraComponents = useSelector((state) => selectAllExtraComponents(state));
  const projectExtraComponents = useSelector((state) => selectAllProjectExtraComponents(state));

  const screenExtraComponents = React.useMemo(() => {
    const items: IProjectExtraComponentGroupRefVal[] = new Array<IProjectExtraComponentGroupRefVal>();

    extraComponents.forEach((extraComponent) => {
      const componentItem: IProjectExtraComponentRefVal = {
        extraComponent: extraComponent,
        projectExtraComponent: projectExtraComponents.find(
          (a) => a.extraComponentID === extraComponent.extraComponentID
        ),
      };

      if (extraComponent.extraComponentGroupID) {
        const item = items.find((a) => a.extraComponentGroupID === extraComponent.extraComponentGroupID);
        if (item) {
          item.items.push(componentItem);
          item.items.sort(
            (a, b) => (a.extraComponent.extraComponentOrder || 0) - (b.extraComponent.extraComponentOrder || 0)
          );
        } else {
          items.push({
            key: extraComponent.extraComponentID,
            extraComponentGroupID: extraComponent.extraComponentGroupID,
            sort: extraComponent.extraComponentGroupOrder || 0,
            imageID: (extraComponent.imageID || extraComponent.extraComponentGroupImageID) as number,
            items: [componentItem],
          });
        }
      } else {
        items.push({
          key: extraComponent.extraComponentID,
          imageID: extraComponent.imageID,
          sort: -1,
          items: [componentItem],
        });
      }
    });

    items.sort((a, b) => {
      if (a.sort === b.sort) return 0;
      if (a.sort === -1) return 1;
      if (b.sort === -1) return -1;
      return a.sort - b.sort;
    });
    return items;
  }, [extraComponents, projectExtraComponents]);

  return [screenExtraComponents];
};
