import { createSelector } from '@reduxjs/toolkit';
import { PlenumDataSupply } from 'application/shared/PlenumDataSupply';
import { selectProjectState } from 'redux/store';
import { plenumConnectionAdapter } from '../adapters/plenumConnectionAdapter';

export const {
  selectAll: selectAllPlenumConnections,
  selectById: selectPlenumConnectionByKey,
  selectEntities: selectPlenumConnectionEntities,
} = plenumConnectionAdapter.getSelectors((state) => selectProjectState(state).plenumConnections);

export const makeSelectPlenumConnectionsByConnection = () => {
  return createSelector(
    (state: any, valveConnectionKey: string) => valveConnectionKey,
    selectAllPlenumConnections,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (valveConnectionKey, connections) =>
      connections.filter((connection) => connection.valveConnectionKey === valveConnectionKey)
  );
};

export const makeSelectPlenumConnectionsByDirection = () => {
  return createSelector(
    (state: any, supply: PlenumDataSupply) => supply,
    (state: any, supply: PlenumDataSupply, plenumIndex: number) => plenumIndex,
    (state: any) => selectProjectState(state).valveConnections,
    selectAllPlenumConnections,
    // Then, an "output selector" that receives all the input results as arguments
    // and returns a final result value
    (supply, plenumIndex, valveConnections, plenumConnections) =>
      plenumConnections.filter(
        (plenumConnection) =>
          valveConnections.entities[plenumConnection.valveConnectionKey]?.supply === supply &&
          valveConnections.entities[plenumConnection.valveConnectionKey]?.plenumIndex === plenumIndex
      )
  );
};
